.abox {
    height: 38px;
    line-height: 38px;
    text-align: center;
    border: 1px solid gray;
}

.time-picker {
    margin: 15px 15px 15px 0px;
}
.table-b {
    margin: 15px 0px 15px 0px;
}
